<template>
  <div class="regular-slider">
    <div class="regular-slider__with-navigation">
      <RegularSliderNavigation
        :current-nav="currentNav"
        @nav-change="calcCurrNav"
      />
      <component :is="sliders[currentNav]" />
    </div>
    <PromotionProductList
      class="regular-slider__promotion"
      :title="_T('@Promotion slider title')"
    />
  </div>
</template>

<script setup>
import NoveltyProductList from "~/modules/shared/components/sliders/productsByLabel/NoveltyProductList.vue";
import BestSellerProductList from "~/modules/shared/components/sliders/productsByLabel/BestSellerProductList.vue";
import RegularSliderNavigation from "~/modules/home/components/regularSlider/RegularSliderNavigation.vue";
import PromotionProductList from "~/modules/shared/components/sliders/productsByLabel/PromotionProductList.vue";

const currentNav = ref(1);

const sliders = {
  1: NoveltyProductList,
  2: BestSellerProductList,
};

function calcCurrNav(idx) {
  currentNav.value = idx;
}
</script>

<style lang="scss" scoped>
.regular-slider {
  @include flex-container(column, flex-start, center);
  gap: 24px;

  background-color: white;

  padding: 24px 16px;

  @include bigMobile {
    padding: 16px 0 16px 16px;
  }

  &__with-navigation {
    @extend %width-main;

    @include flex-container(column, flex-start, center);

    gap: 32px;

    @include bigMobile {
      gap: 16px;
    }
  }

  &__promotion {
    @extend %width-main;
  }
}
</style>
